import React from 'react';
import { AiFillEdit } from "react-icons/ai";

import Chip from '@mui/material/Chip';

import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';

import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';

import 'Css/device.css';
import { FaCheckCircle } from "react-icons/fa";
import { MdDisabledByDefault } from "react-icons/md";
import { MdHourglassDisabled } from "react-icons/md";
import { FaHourglass } from "react-icons/fa";
import ReactLoading from 'react-loading';
import { PiNumpadBold } from "react-icons/pi";
import { BiRfid } from "react-icons/bi";
import { PiShareNetworkFill } from "react-icons/pi";


class PuntoControllo extends React.Component {

  constructor(props) {
    super(props);
    this.keyComponent = 0
    this.state = {
      selectedHeadOption: 0,
      customImpulseTimeUnit: "s",
      detailsLoading: true,
      scrolledToBottom: false,
      openType: 'impulse'
    }

    this.props.fetchUserRfid();
    this.scrollingRef = React.createRef();

  }

  componentDidMount() {
    if (!this.state.details) {
      let id = window.location.href.substring(window.location.href.indexOf("?id=") + 4);
      let details = this.props.readDetails(id);
      details.then((result) => {
        this.setState({
          detailsLoading: false,
          details: result
        })
      });
    }
    this.scrollToBottom();

  }


  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (!this.state.scrolledToBottom && this.scrollingRef.current) {
      this.setState({
        scrolledToBottom: true
      });

      const { scrollHeight, clientHeight } = this.scrollingRef.current;
      this.scrollingRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }

  onGenerale() {
    this.setState({
      selectedHeadOption: 0,
      scrolledToBottom: false
    });
  }
  onWIFI() {
    this.setState({
      selectedHeadOption: 1
    });
  }
  onPin() {
    this.setState({
      selectedHeadOption: 2
    });
  }
  onShare() {
    this.setState({
      selectedHeadOption: 3
    });
  }

  onSalva() {
    if (!this.state.details) {
      return;
    }
    this.props.register({
      id: this.state.details.device.id,
      wifiName: this.state.details.device.wifiName,
      wifiPassword: this.state.details.device.wifiPassword,
      name: this.state.deviceName,
      tempoImpulso: this.state.tempoImpulso == "custom" ? this.getCustomImpulseTime() : parseFloat(this.state.tempoImpulso),
      isModifica: true,
      hosts: this.state.details.device.hosts,
      pulseMode: this.state.details.device.pulseMode,
      openType: this.state.details.device.openType,
      serialNumber: this.state.details.device.serialNumber
    })
  }

  getCustomImpulseTime() {
    if (this.state.customImpulseTime) {
      if (this.state.customImpulseTimeUnit == "s") {
        return parseFloat(this.state.customImpulseTime);
      } else if (this.state.customImpulseTimeUnit == "m") {
        return parseFloat(this.state.customImpulseTime * 60);
      }
    }
    return null;
  }

  onRimuovi() {
    this.props.setShowModaleConferma({
      header: <strong>Elimina dispositivo</strong>,
      body: <>Stai per eliminare in modo irreversibile il dispositivo <strong>{this.state.deviceName}</strong></>,
      esegui: () => {
        this.props.rimuoviDevice(this.state.details.device.id);
      },
      loading: false,
      confirmStyle: "danger"
    });
  }

  addNewSharedHost() {
    let details = this.state.details;
    details.device.hosts.push(this.state.tempSharedHost);

    this.setState({
      tempSharedHost: "",
      details: details
    });
  }

  deleteSharedHost(hostToDelete) {
    let details = this.state.details;
    let indexToDelete = -1;

    for (let i = 0; i < details.device.hosts.length; i++) {
      let host = details.device.hosts[i];
      if (host == hostToDelete) {
        indexToDelete = i;
        break;
      }
    }

    delete details.device.hosts[indexToDelete];

    this.setState({
      details: details
    });
  }

  onChangeDetails(k, v) {
    let details = this.state.details;
    details.device[k] = v;
    this.setState({
      details: details
    });
  }


  render() {
    let mobileVer = window.outerWidth < 455;
    let details = {};
    details.device = {};
    if (this.state.details) {
      details = this.state.details;

      if (!this.state.tempoImpulso && this.state.tempoImpulso != 0) {
        let impulseTime = details.device.tempoImpulso;
        if (impulseTime != 0 && impulseTime != 1 && impulseTime != 2 && impulseTime != 3 && impulseTime != 4 && impulseTime != 5) {
          this.setState({
            tempoImpulso: "custom",
            customImpulseTime: impulseTime,
            customImpulseTimeUnit: "s"
          });
        } else {
          this.setState({
            tempoImpulso: details.device.tempoImpulso == 0 ? 0.5 : details.device.tempoImpulso
          })
        }
      }

      if (!this.state.deviceName) {
        this.setState({
          deviceName: details.device.name
        })
      }
    }



    let wifiSignal = details.device.wifiSignal * (-1);
    let wifiSignalIcon = wifiSignal >= 99 ? <SignalCellular4BarIcon />
      :
      wifiSignal >= 60 ? <SignalCellular3BarIcon />
        :
        wifiSignal >= 40 ? <SignalCellular2BarIcon />
          :
          wifiSignal >= 10 ? <SignalCellular1BarIcon />
            :
            <SignalCellular0BarIcon />;

    let graphicPercentage;

    if (details.device.voltage >= 3.3) {
      graphicPercentage = "100%";
    }
    else if (details.device.voltage >= 3.2) {
      graphicPercentage = "80%";
    }
    else if (details.device.voltage >= 3.1) {
      graphicPercentage = "50%";
    }
    else if (details.device.voltage >= 3.0) {
      graphicPercentage = "40%";
    }
    else if (details.device.voltage >= 2.9) {
      graphicPercentage = "30%";
    }
    else if (details.device.voltage >= 2.8) {
      graphicPercentage = "20%";
    }
    else if (details.device.voltage >= 2.7) {
      graphicPercentage = "15%";
    }
    else if (details.device.voltage >= 2.6) {
      graphicPercentage = "10%";
    }
    else if (details.device.voltage >= 2.5) {
      graphicPercentage = "5%";
    }
    else {
      graphicPercentage = "1%";
    }

    let batteryIcon = details.device.voltage >= 3.2 ? <BatteryStdIcon />
      :
      details.device.voltage >= 3.0 ? <Battery60Icon />
        :
        details.device.voltage >= 2.9 ? <Battery50Icon />
          :
          details.device.voltage >= 2.8 ? <Battery30Icon />
            :
            details.device.voltage >= 2.7 ? <Battery20Icon />
              :
              <BatteryAlertIcon />;

    let segnaleWifi = details.device.online ?
      <>{wifiSignal}% {wifiSignalIcon}</>
      :
      <>Assente</>;

    let hosts = details.device.hosts ? details.device.hosts.map((host) => {
      return (
        <label class="sharedHost">{host}<button onClick={() => this.deleteSharedHost(host)}>x</button></label>
      );
    }) : "";

    return (
      <>
        {this.state.detailsLoading ? <div className="loadingBox"><ReactLoading type="spin" color={"#000"} /></div>
          :
          <div id="accessPoint">
            <div id="accessPointHead">
              <div>
                <AiFillEdit />
                <input type="text" style={{ fontSize: mobileVer ? "1rem" : "1.2rem" }} value={this.state.deviceName} onChange={(e) => { this.setState({ deviceName: e.currentTarget.value }); }} />
              </div>
              <div>
                <button className="btn btn-sm btn-danger mr-2" onClick={() => this.onRimuovi()}>
                  Rimuovi
                </button>
                <button className="btn btn-sm btn-success" onClick={() => this.onSalva()}>Salva</button>
              </div>
            </div>

            <div id="accessPointDetails">
              <button onClick={() => this.onGenerale()} className={this.state.selectedHeadOption == 0 ? "selected" : ""}>Generale</button>
              <button onClick={() => this.onWIFI()} className={this.state.selectedHeadOption == 1 ? "selected" : ""}>WIFI</button>
              <button onClick={() => this.onPin()} className={this.state.selectedHeadOption == 2 ? "selected" : ""}>Ospiti</button>
              {!details.isHost ?
                <button onClick={() => this.onShare()} className={this.state.selectedHeadOption == 3 ? "selected" : ""}>Condividi</button>
                : ""}
            </div>


            {this.state.selectedHeadOption == 0 ?
              <div className="accessPointDetail">
                <div className="row">
                  <div className="col">
                    <strong>Stato</strong>
                  </div>
                  <div className="col">
                    {details.device.online ?
                      <Chip size={mobileVer ? "small" : ""} label="ONLINE" style={{ background: "rgba(0,255,0,.8)", color: "white", fontWeight: "bold", fontSize: mobileVer ? "1.5em" : "inherit" }} />
                      :
                      <Chip size={mobileVer ? "small" : ""} label="OFFLINE" style={{ background: "rgba(255,0,0,.8)", color: "white", fontWeight: "bold" }} />
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Numero Seriale</strong>
                  </div>
                  <div className="col">
                    {details.device.serialNumber}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Segnale WIFI</strong>
                  </div>
                  <div className="col">
                    {segnaleWifi}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Batteria</strong>
                  </div>
                  <div className="col">
                    {graphicPercentage} {batteryIcon}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Versione</strong>
                  </div>
                  <div className="col">
                    {details.device.version || "--"}
                  </div>
                </div>

                <div id="device-openType-container" className="device-openType">
                  <div>
                    <h2>TIPOLOGIA</h2>
                  </div>
                  <div id="device-openType-container-btns">
                    <button className={"dmd-btn-switch " + (details.device.openType == 'impulse' ? "on" : "")} onClick={() => this.onChangeDetails("openType", "impulse")}>IMPULSO</button>
                    <button className={"dmd-btn-switch " + (details.device.openType == 'switch' ? "on" : "")} onClick={() => this.onChangeDetails("openType", "switch")}>INTERRUTTORE</button>
                  </div>
                </div>

                {details.device.openType == 'impulse' ?
                  <>
                    <div id="device-openType-container-impulseMode" className="device-openType">
                      <h2>MODALITÀ</h2>
                      <div className="mt-1" id="pulse-mode">
                        <button className={"dmd-btn-switch " + (details.device.pulseMode == "DIRECT" ? "on" : "")} onClick={() => this.onChangeDetails("pulseMode", "DIRECT")}>CONTINUO</button>
                        <button className={"dmd-btn-switch " + (details.device.pulseMode == "ALTERNATE" ? "on" : "")} onClick={() => this.onChangeDetails("pulseMode", "ALTERNATE")}> ALTERNATO</button>
                      </div>
                    </div>
                    <div id="device-openType-container-impulseTime" className="device-openType">
                      <h2>TEMPO</h2>
                      <div id="impulseTime">
                        <select className="dmd-select" value={this.state.tempoImpulso} onChange={(e) => { this.setState({ tempoImpulso: e.currentTarget.value }) }}>
                          <option value={0}>0.5s</option>
                          <option value={1}>1s</option>
                          <option value={2}>2s</option>
                          <option value={3}>3s</option>
                          <option value={4}>4s</option>
                          <option value={5}>5s</option>
                          <option value={"custom"}>Altro</option>
                        </select>
                        {this.state.tempoImpulso == "custom" &&
                          <div id="customImpulseTime">
                            <input type="number" pattern="\d*" value={this.state.customImpulseTime} onChange={(e) => { this.setState({ customImpulseTime: e.currentTarget.value }) }} />
                            <select value={this.state.customImpulseTimeUnit} onChange={(e) => { this.setState({ customImpulseTimeUnit: e.currentTarget.value }) }} >
                              <option value={"s"}>secondi</option>
                              <option value={"m"}>{this.state.customImpulseTime == 1 ? "minuto" : "minuti"}</option>
                            </select>
                          </div>}
                      </div>
                    </div>
                  </>
                  : ""}

                <div className="row">
                  <div className="col">
                    <label id="logs-title">LOGS</label>
                    {details.device.log ?
                      <div id="logs" ref={this.scrollingRef} dangerouslySetInnerHTML={{ __html: details.device.log.content }} />
                      : "Non ci sono log disponibili al momento"}
                  </div>
                </div>
              </div> : ""
            }

            {this.state.selectedHeadOption == 1 ?
              <div className="accessPointDetail">
                <div className="row">
                  <div className="col">
                    <strong>IP</strong>
                  </div>
                  <div className="col">
                    {details.device.localIp || "Non disponibile"}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>SSID</strong>
                  </div>
                  <div className="col">
                    <input type="text" disabled value={details.device.wifiName} style={{ width: mobileVer ? "80%" : "100%", fontSize: mobileVer ? "90%" : "100%" }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Password</strong>
                  </div>
                  <div className="col">
                    <input type="text" disabled value={"********"} style={{ width: mobileVer ? "80%" : "100%", fontSize: mobileVer ? "90%" : "100%" }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Segnale</strong>
                  </div>
                  <div className="col">
                    {segnaleWifi}
                  </div>
                </div>
              </div> : ""
            }
            {this.state.selectedHeadOption == 2 ?
              <div className="accessPointDetail">
                {details.device.guests && details.device.guests.length ?
                  <>
                    {details.device.guests.map(guest => {

                      let expirationDate = "ILLIMITATO";
                      if (guest.expirationDate) {
                        let expirationDateObject = new Date(guest.expirationDate);
                        let month = expirationDateObject.getMonth() + 1;
                        expirationDate = [
                          expirationDateObject.getDate() < 10 ? "0" + expirationDateObject.getDate() : expirationDateObject.getDate(),
                          month < 10 ? "0" + month : month,
                          expirationDateObject.getFullYear()].join("/");
                      }

                      return (
                        <div className="device-details-guest">
                          <h1 style={{ display: 'flex', justifyContent: 'flex-start', columnGap: '2px' }}>
                            {guest.nominativo}
                            {guest.createdByHost && <PiShareNetworkFill style={{ color: 'rgba(105, 115, 255, 1)' }} />}
                          </h1>
                          <div>
                            <div >
                              <PiNumpadBold /> <strong>{guest.pin}</strong>
                            </div>
                            <div className={guest.rfid ? "" : "deactivated"}>
                              <BiRfid />
                              {guest.rfid || "NO TESSERA"}
                            </div>
                            <div id="device-details-expiration-guest-date" className={expirationDate == "ILLIMITATO" || expirationDate <= new Date() ? "success" : "danger"}>
                              {guest.expirationDate && <FaHourglass />}
                              {!guest.expirationDate && <MdHourglassDisabled />}
                              {expirationDate}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                  : "Nessun Pin associato"}
              </div>
              : ""
            }
            {!details.isHost && this.state.selectedHeadOption == 3 ?
              <div className="accessPointDetail">
                <div id="addSharedHost">
                  <input type="text" onChange={(e) => this.setState({ tempSharedHost: e.currentTarget.value })} value={this.state.tempSharedHost} /> <button disabled={!this.state.tempSharedHost} onClick={() => this.addNewSharedHost()}><i class="fa-solid fa-share"></i></button>
                </div>
                {hosts}
              </div> : ""
            }
          </div >
        }
      </>
    )
  }
}

export default PuntoControllo
export { PuntoControllo }