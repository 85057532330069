import { observable, action, makeObservable } from "mobx";
import CryptoAES from 'crypto-js/aes';
import { toast } from 'react-toastify';

class DeviceStore {

  constructor(rootStore) {
    this.rootStore = rootStore
    this.service = rootStore.service
    makeObservable(this);
  }

  @observable user = null;
  @observable config = require('../../config/config.json');

  @observable registerLoading = false;

  @observable deviceSetupProcedureStarted = false;

  @action
  setDeviceSetupProcedureStarted(val) {
    this.deviceSetupProcedureStarted = val;
  }

  @observable modalErrorMessage = "";

  @action
  setModalErrorMessage(val) {
    this.modalErrorMessage = val;
  }

  @observable pulseErrorCounter = 0;

  @action
  getAndIncrementPulseErrorCounter() {
    return this.pulseErrorCounter++;
  }

  @action
  resetPulseErrorCounter() {
    this.pulseErrorCounter = 0;
  }

  @observable operationErrorWhileCommunicatingCounter = 0;

  @action
  getAndIncrementOperationErrorWhileCommunicatingCounter() {
    return this.operationErrorWhileCommunicatingCounter++;
  }

  @action
  resetOperationErrorWhileCommunicatingCounter() {
    this.operationErrorWhileCommunicatingCounter = 0;
  }

  @observable checkingNetworkAuth = false;

  @action
  setCheckingNetworkAuth(val) {
    this.checkingNetworkAuth = val;
  }

  @action
  isCheckingNetworkAuth() {
    return this.checkingNetworkAuth;
  }

  @action
  register(device) {
    this.registerLoading = true;

    let params = {
      id: device.id,
      name: device.name,
      wifiName: device.wifiName,
      wifiPassword: device.wifiPassword,
      ownerUserEmail: this.rootStore.userStore.getUser().email,
      serialNumber: device.serialNumber,
      tempoImpulso: device.tempoImpulso,
      hosts: device.hosts,
      pulseMode: device.pulseMode,
      openType: device.openType,
    }
    this.service.doAjax('POST', this.config.controlPointAPI + '/register', params).then(response => {
      if (response.success) {

        if (response.result) {
          if (!device.isModifica) {
            let messageToShowCrypted = CryptoAES.encrypt("Registrazione del dispositivo " + device.name + " effettuata con successo.", this.config.phrase);

            const context = `/${this.config.context}`
            //location.href = context + "/dispositivi?dmd=" + messageToShowCrypted;
            this.deviceSetupProcedureStarted.setupDone = true;
            this.deviceSetupProcedureStarted.connectedDevice.gatt.disconnect();
          } else {
            toast.info("Dispositivo " + device.name + " modificato con successo.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.info("Si e' verificato un problema, riprovare.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      } else {
        let message = response && response.result ? response.result : "Credenziali errate o servizio non raggiungibile.";
        this.rootStore.modalsStore.setToastError({ header: "Registrazione non effettuata", body: message });
        this.timeoutError = message;
      }
      this.registerLoading = false;
    })
  }

  @observable devices = null;
  @observable devicesLoading = false;

  @action
  getAll(hideLoading) {
    this.devicesLoading = hideLoading ? false : true;

    let params = {
      ownerUserEmail: this.rootStore.userStore.getUser().email
    }
    this.service.doAjax('POST', this.config.controlPointAPI + '/read', params).then(response => {
      if (response.success) {
        this.devices = response.result.devices;
      } else {
        //
      }
      this.devicesLoading = false;
    })
  }

  @action
  async generateReportPdf(rowsToGenerator) {
    const url = this.config.controlPointAPI + '/pdf?email=' + this.rootStore.userStore.getUser().email + "&rows=" + btoa(rowsToGenerator);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      });

      if (!response.ok) {
        throw new Error('Errore durante il download del PDF');
      }

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'Report'+this.formatDateForReportName(new Date())+'.pdf'); // Specifica il nome del file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Rilascia l'oggetto URL per evitare memory leak
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Errore durante il download del PDF:', error);
    }
  }

  @action
  deleteRecordRows(rowsToDelete) {
    this.service.doAjax('GET', this.config.controlPointAPI + '/deleteReportRows?email=' + this.rootStore.userStore.getUser().email+ "&rows=" + btoa(rowsToDelete), {}).then(response => {
      if (response.success) {
        this.rootStore.logStore.fetchLog(0)
      } else {
        //TODO
      }
    })
  }

  formatDateForReportName(date) {
    // Ottieni i componenti della data
    let day = date.getDate();
    let month = date.getMonth() + 1; // I mesi in JavaScript partono da 0
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Aggiungi uno zero iniziale ai numeri che hanno una sola cifra
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    // Combina i componenti nella stringa formattata
    return `${day}${month}${year}_${hours}${minutes}${seconds}`;
  }

  @action
  readDetails(id) {
    return new Promise((resolve, reject) => {

      let params = {};

      this.service.doAjax('GET', this.config.controlPointAPI + '/read_details?id=' + id + '&&ownerUserEmail=' + this.rootStore.userStore.getUser().email, params).then(response => {
        if (response.success) {
          return resolve(response.result);
        } else {
          return reject('user_not_found');
        }
        this.devicesLoading = false;
      })
    });
  }

  @observable openControlPointLoading = false;

  @action
  openControlPoint(serialNumber) {
    this.openControlPointLoading = true;

    let params = {
      ownerUserEmail: this.rootStore.userStore.getUser().email,
      serialNumber: serialNumber
    }
    this.service.doAjax('POST', this.config.controlPointAPI + '/send_instant_impulse', params).then(response => {
      if (response.success) {
        //
      } else {
        //
      }
      this.openControlPointLoading = false;
    })
  }

  @observable generatedPin1 = null;
  @observable generatedPin2 = null;
  @observable generatedPin3 = null;

  @action
  setGeneratedPin(pinNumber, value) {
    if (pinNumber == 1) {
      this.generatedPin1 = value;
    }
    else if (pinNumber == 2) {
      this.generatedPin2 = value;
    }
    else if (pinNumber == 3) {
      this.generatedPin3 = value;
    }
  }

  @action
  generatePin(pinNumber) {
    this.service.doAjax('GET', this.config.userAPI + '/pin', {}).then(response => {
      if (response.success) {
        this.setGeneratedPin(pinNumber, response.result);
      } else {
        //
      }
    });
  }

  @action
  rimuoviDevice(id) {
    this.rimuoviDeviceLoading = true;

    let params = {
      id: id,
      ownerUserEmail: this.rootStore.userStore.getUser().email
    }
    this.service.doAjax('POST', this.config.controlPointAPI + '/delete', params).then(response => {
      if (response.success) {
        this.getAll();

        let messageToShowCrypted = CryptoAES.encrypt("Dispositivo rimosso", this.config.phrase);

        const context = `/${this.config.context}`
        location.href = context + "/dispositivi?dmd=" + messageToShowCrypted;
      } else {
        //
      }
      this.rimuoviDeviceLoading = false;
    })
  }

  @observable rimuoviRFIDLoading = false;

  @action
  getRimuoviRFIDLoading() {
    return this.rimuoviRFIDLoading;
  }

  @action
  rimuoviRFID(externalParams) {
    this.rimuoviRFIDLoading = true;

    let params = {
      id: externalParams.id,
      rfid: externalParams.rfid
    }
    this.service.doAjax('POST', this.config.controlPointAPI + '/deleteRfid', params).then(response => {
      if (response.success) {
        let messageToShowCrypted = CryptoAES.encrypt("RFID rimosso", this.config.phrase);

        const context = `/${this.config.context}`
        location.href = context + "/punto_controllo?id=" + externalParams.id + "&dmd=" + messageToShowCrypted;
      } else {
        //
      }
      this.rimuoviRFIDLoading = false;
    })
  }


  @observable getAllRFIDLoading = false;
  @observable allRFID;

  @action
  getAllRFID() {
    this.getAllRFIDLoading = true;
    let params = {}

    this.service.doAjax('GET', this.config.controlPointAPI + '/all_rfid?owner=' + this.rootStore.userStore.getUser().email, params).then(response => {
      if (response.success) {
        this.allRFID = response.result;
      } else {
        //
      }
      this.getAllRFIDLoading = false;
    })
  }

  // //

  @observable devicePins = null;

  @action
  getDevicePins(device, denyModal) {
    this.getDevicePinsLoading = true;

    let params = {
      id: device.id
    }
    this.service.doAjax('POST', this.config.controlPointPinAPI + '/read', params).then(response => {
      if (response.success) {
        this.devicePins = response.result;
      } else {
        let message = response && response.result ? response.result : "Credenziali errate o servizio non raggiungibile.";
        this.rootStore.modalsStore.setToastError({ header: "Registrazione non effettuata", body: message });
        this.timeoutError = message;
      }
      this.getDevicePinsLoading = false;

    })
  }

  @observable getDevicePinsLoading = false;

  @action
  registerDevicePins(device) {
    this.getDevicePinsLoading = true;

    let params = {
      id: device.id,
      pins: device.pins
    }
    this.service.doAjax('POST', this.config.controlPointPinAPI + '/register', params).then(response => {
      if (response.success) {
        this.getDevicePins(device, true);
        toast.info("Chiavi del dispositivo " + device.name + " aggiornate.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //
      }
      this.getDevicePinsLoading = false;
    })
  }

  @observable showModalDirittiAccesso = false;

  @action
  setShowModalDirittiAccesso(value) {
    this.showModalDirittiAccesso = value;
  }

  @observable deviceAssociati = new Array();

  @action
  setDeviceAssociati(value) {
    this.deviceAssociati = value;
  }

}

export default DeviceStore;